// @flow
import {type Timeline} from "./Timeline"
import {type ITimelineItem} from "../TimelineItem"

export type MergePredicate<T: ITimelineItem> = (T, T, Timeline<T>) => boolean

/**
 * Given a timeline and a predicate function, merge items in
 * the timeline together and return a new timeline.
 *
 * @example
 * const predicate = (a, b) => a.state === b.state && a.activity === b.activity;
 * const tl1 = mockTimeline();
 * const tl2 = mergeTimelineItems(tl1, predicate);
 * console.log(tl1);
 * console.log(tl2);
 */
export function mergeTimelineItems<T: ITimelineItem>(timeline: Timeline<T>, predicate: MergePredicate<T>) {
  return timeline.reduce((output, item) => {
    if (output.length === 0) {
      output.push(item)
    } else {
      const prev = output[output.length - 1]
      if (predicate(prev, item, output)) {
        output[output.length - 1] = merge(prev, item)
      } else {
        output.push(item)
      }
    }
    return output
  }, [])
}

function merge<T: ITimelineItem>(a: T, b: T): T {
  return {
    ...a,
    interval: {
      startMillis: a.interval.startMillis,
      endMillis: b.interval.endMillis
    }
  }
}
