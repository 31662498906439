// @flow
import {type Validator, arrayOf} from "vimana-types"
import {type ITimelineItem, validateTimelineItem} from "../TimelineItem"

/**
 * A list of timeline items ordered by time.
 */
export type Timeline<T: ITimelineItem> = T[]

/**
 * Validate a timeline.
 */
export const validateTimeline: Validator<Timeline<*>> = arrayOf(validateTimelineItem)
