// @flow

import {type ITimelineItem} from "../TimelineItem"
import type {IInterval} from "../Interval"
import {END_OF_TIME} from "../constants"

function isInInterval(value: number, startMillis: number, endMillis: number): boolean {
  return value > startMillis && value < endMillis
}

const getDataInInterval = (values: Array<ITimelineItem>, interval: IInterval): Array<ITimelineItem> => {
  if (!values || values.length === 0) {
    return []
  }

  return values.filter(({interval: {startMillis, endMillis}}) => {
    const {startMillis: windowStartMillis, endMillis: windowEndMillis} = interval

    endMillis = endMillis === END_OF_TIME ? Date.now() : endMillis
    return (
      isInInterval(startMillis, windowStartMillis, windowEndMillis) ||
      isInInterval(endMillis, windowStartMillis, windowEndMillis) ||
      (startMillis <= windowStartMillis && windowEndMillis <= endMillis)
    )
  })
}

/**
 * Slice timeline items based on the interval.
 *
 * @example
 * const items = [
 * { key: "A", interval: { startMillis: 0, endMillis: 1 } },
 * { key: "B", interval: { startMillis: 1, endMillis: 2 } },
 * { key: "C", interval: { startMillis: 2, endMillis: 3 } },
 * { key: "D", interval: { startMillis: 3, endMillis: 4 } },
 * { key: "E", interval: { startMillis: 5, endMillis: 6 } },
 * { key: "F", interval: { startMillis: 6, endMillis: 7 } },
 * { key: "G", interval: { startMillis: 7, endMillis: 9 } },
 * { key: "H", interval: { startMillis: 9, endMillis: 10 } },
 * { key: "I", interval: { startMillis: 11, endMillis: 12 } }
 * ];
 *
 * console.log(sliceTimeline(items, { startMillis: 4, endMillis: 8 }));
 */
export function sliceTimeline(itemList: Array<ITimelineItem>, interval: IInterval): Array<ITimelineItem> {
  const itemListInInterval = getDataInInterval(itemList, interval)

  return itemListInInterval.map((item: ITimelineItem) => {
    const startMillis =
      item.interval.startMillis < interval.startMillis ? interval.startMillis : item.interval.startMillis

    let {endMillis} = item.interval
    // Slice endMillis to "now" when itemEnd > now
    endMillis = item.interval.endMillis > Date.now() ? Date.now() : item.interval.endMillis
    // Slice endMillis to "interval.endMillis" when itemEnd > interval.endMillis
    endMillis = endMillis > interval.endMillis ? interval.endMillis : endMillis

    return {
      ...item,
      interval: {
        startMillis,
        endMillis
      }
    }
  })
}
