// @flow

import {type Validator, shapeOf} from "vimana-types"
import {type IInterval, validateInterval} from "../Interval"

/**
 * A base interface for items which occur in a timeline.
 */
export type ITimelineItem = {
  interval: IInterval
}

/**
 * Validate an item in a timeline.
 */
export const validateTimelineItem: Validator<ITimelineItem> = shapeOf({
  interval: validateInterval
})
