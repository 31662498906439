// @flow

import {type UnixTimeMS, validateUnixTimeMS, validateObject, invariant} from "vimana-types"

/**
 * A time range expressed in milliseconds since the unix epoch.
 */
export type IInterval = {
  startMillis: UnixTimeMS,
  endMillis: UnixTimeMS
}

/**
 * Validate a time interval.
 */
export function validateInterval(input: {startMillis: number, endMillis: number}): IInterval {
  validateObject(input)
  validateUnixTimeMS(input.startMillis)
  validateUnixTimeMS(input.endMillis)
  invariant(input.endMillis >= input.startMillis, "Interval must end after the start.")
  return input
}
