// @flow
import {type IInterval} from "./Interval"

/**
 * Determine whether two interval objects overlap.
 *
 * @example
 * const a = mockInterval();
 * const b = mockInterval()
 * console.log(isIntervalOverlapping(a, b));
 */
export function isIntervalOverlapping(a: IInterval, b: IInterval) {
  return (
    (b.startMillis >= a.startMillis && b.endMillis < a.endMillis) ||
    (b.startMillis <= a.startMillis && b.endMillis > a.startMillis) ||
    (b.startMillis >= a.startMillis && b.startMillis < a.endMillis)
  )
}
