// @flow
import {type DurationMS} from "vimana-types"
import {type ITimelineItem} from "../TimelineItem"
import {type IInterval} from "../Interval"

export type TimelineItemCloner<T: ITimelineItem> = (T, IInterval) => T

export type TimelineModifierStrategy<T> = {
  clone?: TimelineItemCloner<T>,
  minimumDuration?: DurationMS
}

/**
 * Create a function which, given a timeline and a new timeline item, returns
 * a new timeline with the timeline item in the right place, overwriting, duplicating or updating
 * overlapping items where necessary.
 *
 * @example
 *
 * const updateTimeline = createTimelineModifier({})
 *
 * const tl1 = updateTimeline([], mockTimelineItem());
 * const tl2 = updateTimeline(tl1, mockTimelineItem());
 * const tl3 = updateTimeline(tl2, mockTimelineItem());
 *
 * console.log(tl1);
 * console.log(tl2);
 * console.log(tl3);
 */

export function createTimelineModifier(strategy: TimelineModifierStrategy<ITimelineItem>) {
  const {clone = defaultClone, minimumDuration = 1} = strategy

  // eslint-disable-next-line sonarjs/cognitive-complexity
  return (timeline: Array<ITimelineItem>, input: ITimelineItem): Array<ITimelineItem> => {
    const updated = []
    for (let i = 0; i < timeline.length; i++) {
      const candidate = timeline[i]

      const inputIsBeforeCandidate =
        input.interval.startMillis <= candidate.interval.startMillis &&
        input.interval.endMillis <= candidate.interval.startMillis

      const inputIsAfterCandidate =
        input.interval.startMillis >= candidate.interval.endMillis &&
        input.interval.endMillis >= candidate.interval.endMillis

      const inputOverlapsCandidate = !inputIsBeforeCandidate && !inputIsAfterCandidate

      if (inputIsBeforeCandidate) {
        updated.push(input)
        updated.push(...timeline.slice(i))
        return updated
      }

      /* istanbul ignore else */
      if (inputIsAfterCandidate) {
        const isBeforeAndAfterSame =
          input.interval.startMillis === candidate.interval.startMillis &&
          input.interval.endMillis >= candidate.interval.startMillis
        if (!isBeforeAndAfterSame) {
          updated.push(candidate)
        }
      } else if (inputOverlapsCandidate) {
        if (
          candidate.interval.startMillis < input.interval.startMillis &&
          input.interval.startMillis - candidate.interval.startMillis >= minimumDuration
        ) {
          updated.push(
            clone(candidate, {
              startMillis: candidate.interval.startMillis,
              endMillis: input.interval.startMillis
            })
          )
        }

        updated.push(input)

        // Fast forward, adding any intervals that occur after this one
        for (; i < timeline.length; i++) {
          const item = timeline[i]
          if (
            item.interval.endMillis > input.interval.endMillis &&
            item.interval.endMillis - input.interval.endMillis >= minimumDuration
          ) {
            updated.push(
              clone(item, {
                startMillis: input.interval.endMillis,
                endMillis: item.interval.endMillis
              })
            )
            if (i < timeline.length - 1) {
              updated.push(...timeline.slice(i + 1))
            }
            return updated
          }
        }
        return updated
      }
    }

    // If we got this far either the timeline is empty or
    // our input interval is at the end.
    updated.push(input)

    return updated
  }
}

function defaultClone<T>(input: T, interval: IInterval): T {
  return {
    ...input,
    interval
  }
}
